ngb-timepicker{
    &.is-invalid {
        input {
            border-color: $danger;
        }
    }

    &.is-invalid:not(&.without-validation-icon) {
        input {
            background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 2.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
        }
    }

    &.is-valid {
        input {
            border-color: $success;
        }
    }
    &.is-valid:not(&.without-validation-icon) {
        input {
            padding-right: calc(0.75em + 2.3125rem);
            background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%230ec984' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 2.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
        }
    }
}

ngb-timepicker.form-control-sm .ngb-tp-input.form-control {
    @extend .form-control-sm;
}
