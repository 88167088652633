@import "src/styles/variables";
@import "src/styles/bg-color";

.row-toggler-wrap {
  &--right {
    @media only screen and (max-width: map-get($grid-breakpoints, "lg")) {
      padding-right: $row-toggler-width + $table-cell-padding-sm - 0.8rem !important;
    }
    @media only screen and (min-width: map-get($grid-breakpoints, "lg") + 1) {
      padding-right: $row-toggler-width + $table-cell-padding-lg !important;
    }
    &:not(.p-0) {
      @media only screen and (max-width: map-get($grid-breakpoints, "lg")) {
        padding-right: $row-toggler-width + $table-cell-padding-sm !important;
      }
      @media only screen and (min-width: map-get($grid-breakpoints, "lg") + 1) {
        padding-right: $row-toggler-width + $table-cell-padding-lg !important;
      }
    }

    .row-toggler {
      right: 0;
    }
  }

  &--left {
    padding-left: $row-toggler-width + $table-cell-padding !important;

    .row-toggler {
      left: 0;
    }
  }

}

.row-toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute !important;
  top: 0;
  bottom: 0;
  width: $row-toggler-width;
  background-color: $color-brand;
  color: white;
  cursor: pointer;
  transition: background-color .3s ease-out;
  //@extend .bg-gradient-brand;
  //@extend .bg-gradient-brand--v;
  &--success {
    background-color: $success;
  }
  &--danger {
    background-color: $danger;
  }
  &--warning {
    background-color: $warning;
  }
  &--primary {
    background-color: $primary;
  }

  .dropdown-toggle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
      display: none;
    }
  }
}
