@import "bg-color";

.table-responsive {
    .table {
        @extend .border
    }
}

.table {
    margin-bottom: 0 !important;
    background-color: #fff;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;

    &-wrapper-scroll {
        overflow-y: auto;
    }

    &.table-nowrap {
        th, td {
            white-space: nowrap !important;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    font-size: .8rem;

    .table-btn-group {
        &:not(.btn-group-vertical) > .btn:not(:last-of-type) {
            margin-right: 0.25rem;
        }
        &.btn-group-vertical > .btn:not(:last-of-type) {
            margin-bottom: 0.25rem;
        }
    }

    &:not(table .second-level) {
        border-top-width: 0;
        border-right-width: 1px;
        border-bottom-width: 1px;
        box-shadow: 0 2px 0 #e9e9e9, inset 0 2px 0 #e9e9e9;
    }

    tbody + tbody {
        border-top-width: 1px;
    }

    thead {
        box-shadow: 0 2px 0 #e9e9e9;
        z-index: 5;
        position: relative;
    }

    thead th,
    td {
        //border-bottom-width: 3px;
        border-bottom-width: 0;
        border-top-width: 1px;
        border-right-width: 0;
    }

    .second-level {
        transition: all .3s;
        table {
            margin-bottom: $row-toggler-width;
        }

        > td {
            padding: 0 0 0 $row-toggler-width;
        }

        thead, .table-dark:hover {
            background-color: $table-dark-bg;
        }

        & > td, .table-bordered {
            border-width: 0;
        }
    }

    @media only screen and (max-width: map-get($grid-breakpoints, "lg")) {
        th, td {
            padding: .5rem;
        }

        .second-level .table {
            th, td {
                //padding: .3rem;
            }
        }
    }

    &.two-levels {
        &.table-striped {
            tbody tr {
                &:nth-of-type(odd) {
                    //background-color: transparent;

                    &:not(.second-level) {
                        //background-color: rgba($color-brand, .05);
                    }
                }
            }

            .first-level {
                &:nth-of-type(odd) tr:not(.second-level) {
                    //background-color: rgba($color-brand, .05);
                }

                //&:nth-of-type(even) {
                //background-color: transparent;
                //background-color: #fff;
                //}
            }

            .second-level {
                tr:nth-of-type(odd) {
                    //background-color: transparent !important;
                }
            }
        }

        .second-level {
            thead th {
                border-top-color: $table-dark-border-color;
                border-right-color: $table-dark-border-color;
                border-left-color: $table-dark-border-color;
                border-bottom-color: $table-border-color;
                color: #ffffff;
                background-color: #c1c1c1;
            }
        }
    }

    &.table-hover {
        tbody > tr:hover {
            color: $table-dark-hover-color;
            background-color: $table-dark-hover-bg;
            -webkit-box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
            box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
            z-index: 1;
        }
    }

    &.custom-hover {
        //tbody:nth-of-type(odd),
        tbody:nth-child(odd) {
            //background-color: red;
        }

        tbody:hover
        //tr:hover,
        //td:hover
        {
            color: $table-dark-hover-color;
            background-color: $table-hover-bg;
            //background-color: $table-dark-hover-bg;
            //-webkit-box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
            //box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
            z-index: 1;
        }

    }

    &.table-pointer {
        tbody tr:hover {
            cursor: pointer;
        }
    }

    .badge {
        font-size: .75rem;
    }

    .item-row-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;

        .btn {
            @include button-size(0.15rem, $btn-padding-y-sm, .8rem, .5rem);

            &:not(:first-of-type) {
                margin-left: .3rem;
            }

            margin-top: .2rem;
            margin-bottom: .2rem;
        }
    }

    .filter-row {

        .dropdown-toggle {
            width: 100%;
            max-width: 200px;
            text-align: right;
        }

        .btn {
            font-weight: 400;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            padding: 0.25rem 0.5rem;
            font-size: 0.875rem;
        }

        .dropdown-item {
            padding-left: .5rem;
            padding-right: .5rem;

            &.active, &:active {
                background-color: transparent;
            }
        }
    }

    th {
        &.actions,
        &.event-history-cell {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .actions,
    .event-history-cell {
        text-align: center;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
}

.table-info {
    background-color: #edf2f9;
    text-align: center;
    padding: .6rem;
    position: relative;
    &:after {
        width: 100%;
        height: 1rem;
    }
    &.no-data {
        &:after {
            content: 'Nothing to display';
        }
    }
    &.loading {
        &:after {
            content: 'Loading...';
        }
    }
    &.apply-filters {
        &:after {
            content: 'Choose filters to see the results';
        }
    }
}

virtual-scroller .btn.btn-link {
    line-height: 1.1;
    padding-top: 0;
    padding-bottom: 0;
}

.event-history-cell {
    :not(.btn-group-vertical) > .btn:not(:last-of-type) {
        margin-right: 0.25rem;
    }

    .btn-group-vertical > .btn:not(:last-of-type) {
        margin-bottom: 0.25rem;
    }

    //padding: 0;
    min-width: 35px;
}
