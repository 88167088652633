@import "variables";
.bg-gradient-brand {
    background-color: lighten(desaturate(adjust-hue($color-brand, 10), 50.00), 9.41);
    &--v{
        background-image: -webkit-gradient(linear, right top, left top, from(rgba(51, 148, 225, .18)), to(transparent));
        background-image: linear-gradient(270deg, rgba(51, 148, 225, .18), transparent);
    }
    &--h{
        background-image: -webkit-gradient(linear, left top, left top, from(rgba(51, 148, 225, .18)), to(transparent));
        background-image: linear-gradient(180deg, rgba(51, 148, 225, .18), transparent);
    }
}
.bg-trans-gradient {
    background: linear-gradient(250deg, $profile-dropdown-header-bg--secondary, $color-brand);
}

.bg-warning {
    background-color: #c39534 !important;
}
