@import "variables";

.c-sidebar-brand {
    text-align: center;
    background-color: $color-brand;
    height: 100px;
    display: flex;
    overflow: hidden;
    transition: height linear 0.3s;
    align-items: center;
    justify-content: center;
}

.page-inner:not(.legacy-theme):not(.dark-theme) {
    .sidebar {
        &.sidebar-show {
            border: 0;
            box-shadow: 0 3px 4px 0 rgba(60, 75, 100, 0.14), 0 3px 3px -2px rgba(60, 75, 100, 0.12), 0 1px 8px 0 rgba(60, 75, 100, 0.2);
        }
    }
}

html:not([dir=rtl]) {
    .sidebar-unfoldable {
        &:not(:hover) {
            @media (min-width: 992px) {
                margin-left: -56px;
                .c-sidebar-brand {
                    height: 56px;
                }
                .nav-child {
                    height: 0;
                }
                .sidebar-nav-dropdown-toggle {
                    &.toggled {
                        ~ .nav-child {
                            height: 0;
                        }
                    }
                }

                .nav-title {
                    border-bottom: 1px solid;
                    padding: 0;
                    transition: .4s all;
                    width: 100%;

                    &-text {
                        display: none;
                    }
                }
            }
        }

        .sidebar-minimizer::before {
            @media (min-width: 992px) {
                transform: rotate(-180deg);
            }
        }
    }

    .sidebar {
        margin-left: -256px;

        &.sidebar-lg-show,
        &.sidebar-show {
            @media (min-width: 992px) {
                margin-left: 0;
            }

            &.sidebar-fixed {
                ~ .page-content-wrapper {
                    @media (min-width: 992px) {
                        margin-left: 256px;
                    }
                }

                &.sidebar-minimized,
                &.sidebar-unfoldable {
                    ~ .page-content-wrapper {
                        @media (min-width: 992px) {
                            margin-left: 56px;
                        }
                    }
                }

                @media (min-width: 992px) {
                    border: 0;
                    box-shadow: 0 2px 2px 0 rgba(60, 75, 100, 0.14), 0 3px 1px -2px rgba(60, 75, 100, 0.12), 0 1px 5px 0 rgba(60, 75, 100, 0.2);
                }
            }
        }

        &.sidebar-show {
            margin-left: 0;
        }

        &.sidebar-fixed {
            @media (min-width: 992px) {
                left: 0;
            }
        }
    }

    .sidebar-nav-dropdown-toggle::after {
        margin-left: auto;
    }
}

.sidebar {
    display: flex;
    flex: 0 0 256px;
    flex-direction: column;
    order: -1;
    width: 256px;
    padding: 0;
    box-shadow: none;
    color: #fff;
    transition: box-shadow 0.3s 0.15s, transform 0.3s, margin-left 0.3s, margin-right 0.3s, height 0.3s, width 0.3s, z-index 0s ease 0.3s;
    @media (max-width: 991.98px) {
        --is-mobile: true;
        position: fixed;
        top: 0;
        bottom: 0;
        z-index: 1031;
    }

    &.sidebar-fixed {
        @media (min-width: 992px) {
            position: fixed;
            top: 0;
            bottom: 0;
            z-index: 1030;
        }
    }

    .sidebar-unfoldable {
        transition: transform 0.3s, margin-left 0.3s, margin-right 0.3s, width 0.3s, z-index 0s ease 0s;
    }

    .sidebar-nav-link,
    .sidebar-nav-dropdown-toggle {
        color: rgba(255, 255, 255, 0.8);
        background: 0 0;
    }
}


.sidebar-unfoldable {
    &:not(:hover) {
        z-index: 1031;
        flex: 0 0 56px;

        .sidebar-nav-link,
        .sidebar-nav-dropdown-toggle,
        .sidebar-nav-dropdown-toggle {
            @media (min-width: 992px) {
                overflow: hidden;
                white-space: nowrap;
                border-left: 0;
            }
        }

        &.sidebar-fixed {
            @media (min-width: 992px) {
                z-index: 1031;
                width: 56px;
            }
        }
    }

    @media (min-width: 992px) {
        z-index: 1031 !important;
    }


    .sidebar-minimizer {
        @media (min-width: 992px) {
            position: fixed;
            bottom: 0;
            width: inherit;
        }
    }

    .sidebar-nav-icon:first-child {
        margin-right: .7rem;
    }
}

.sidebar-nav {
    width: inherit;
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0;
    margin-bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    font-size: .9rem;
}

.nav-child {
    height: 0;
    overflow-y: hidden;
    transition: transform 0.3s, height 0.3s, z-index 0s ease 0s;

    .sidebar-nav-link, .sidebar-nav-dropdown-toggle {
        padding-left: 45px;
    }

    &--third {
        .sidebar-nav-link {
            padding-left: 65px;
        }
    }
}

.sidebar-nav-dropdown {
    position: relative;
    transition: background 0.3s ease-in-out;

    &.toggled {
        background-color: #365d91;
    }
}

.sidebar-nav-link.router-link-active,
.active.sidebar-nav-dropdown-toggle {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.05);

    &:before {
        content: ' ';
        position: absolute;
        right: 0;
        border: 0.5rem solid transparent;
        border-right: 0.5rem solid #ebedef;
    }
}

.sidebar-nav-link,
.sidebar-nav-dropdown-toggle {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 15px 20px 15px 5px;
    text-decoration: none;
    white-space: nowrap;
    transition: background 0.3s, color 0.3s;
    color: rgba(255, 255, 255, 0.8);
    background: 0 0;

    &:hover {
        @media (hover: hover), not all {
            color: #fff;
            //background-color: #343a40;
            background-color: rgba(0, 0, 0, .25);
            text-decoration: none;
        }
    }

    &:not(.active):not(.router-link-active) {
        .sidebar-nav-icon {
            color: rgba(255, 255, 255, 0.5);
        }
    }
}

.sidebar-nav-dropdown-toggle {
    cursor: pointer;
    position: relative;
    transition: background 0.3s ease-in-out;

    &:after {
        display: block;
        flex: 0 8px;
        height: 8px;
        content: "";
        background-repeat: no-repeat;
        background-position: center;
        transition: transform 0.3s;

        background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMSAxNCc+PHBhdGggZmlsbD0ncmdiYSgyNTUsIDI1NSwgMjU1LCAwLjUpJyBkPSdNOS4xNDggMi4zNTJsLTQuMTQ4IDQuMTQ4IDQuMTQ4IDQuMTQ4cTAuMTQ4IDAuMTQ4IDAuMTQ4IDAuMzUydC0wLjE0OCAwLjM1MmwtMS4yOTcgMS4yOTdxLTAuMTQ4IDAuMTQ4LTAuMzUyIDAuMTQ4dC0wLjM1Mi0wLjE0OGwtNS43OTctNS43OTdxLTAuMTQ4LTAuMTQ4LTAuMTQ4LTAuMzUydDAuMTQ4LTAuMzUybDUuNzk3LTUuNzk3cTAuMTQ4LTAuMTQ4IDAuMzUyLTAuMTQ4dDAuMzUyIDAuMTQ4bDEuMjk3IDEuMjk3cTAuMTQ4IDAuMTQ4IDAuMTQ4IDAuMzUydC0wLjE0OCAwLjM1MnonLz48L3N2Zz4=);
        transform: rotate(270deg);
    }

    &.toggled {
        &:after {
            transform: rotate(90deg);
        }

        ~ .nav-child {
            height: 100%;
        }
    }
}

.sidebar-nav-icon {
    flex: 0 0 40px;
    text-align: center;
    transition: 0.3s;
    fill: currentColor;
}

.sidebar-minimizer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 50px;
    flex: 0 0 50px;
    -ms-flex-pack: end;
    justify-content: flex-end;
    width: inherit;
    padding: 0;
    cursor: pointer;
    border: 0;
    background-color: rgba(0, 0, 21, 0.2);

    &::before {
        display: block;
        width: 50px;
        height: 50px;
        content: "";
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12.5px;
        transition: 0.3s;
        background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMSAxNCc+PHBhdGggZmlsbD0nIzhhOTNhMicgZD0nTTkuMTQ4IDIuMzUybC00LjE0OCA0LjE0OCA0LjE0OCA0LjE0OHEwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJsLTEuMjk3IDEuMjk3cS0wLjE0OCAwLjE0OC0wLjM1MiAwLjE0OHQtMC4zNTItMC4xNDhsLTUuNzk3LTUuNzk3cS0wLjE0OC0wLjE0OC0wLjE0OC0wLjM1MnQwLjE0OC0wLjM1Mmw1Ljc5Ny01Ljc5N3EwLjE0OC0wLjE0OCAwLjM1Mi0wLjE0OHQwLjM1MiAwLjE0OGwxLjI5NyAxLjI5N3EwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJ6Jy8+PC9zdmc+);
    }

    @media(max-width: 991.98px) {
        display: none
    }

    &:focus,
    &.focus {
        outline: 0
    }

    @media(min-width: 992px) {
        &.sidebar-fixed {
            z-index: 1031;
            width: 56px
        }
    }
}

*[dir=rtl] .sidebar-minimizer::before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.sidebar-brand-full {
    transition: all linear 0.3s;
}

.sidebar-brand-minimized {
    transform: scaleX(0);
    width: 0;
    transition: all linear 0.3s;
}

@media(min-width: 992px) {
    .sidebar-minimized {
        .sidebar-brand-full {
            transform: scaleX(0);
            width: 0;
        }

        .sidebar-brand-minimized {
            width: 40px;
            transform: scaleX(1);
        }
    }

    .sidebar-unfoldable {
        .sidebar-nav:not(.nav-child) {
            padding-bottom: 50px;
            overflow: visible;
        }

        &:not(:hover) {
            .sidebar-brand-full {
                transform: scaleX(0);
                width: 0;
            }

            .sidebar-brand-minimized {
                width: 40px;
                transform: scaleX(1);
            }
        }

        .sidebar-fixed {
            z-index: 1031;
            width: 56px;
        }
    }
}
@media (min-width: 768px) {
    .main > .container-fluid {
        padding-right: 30px;
        padding-left: 30px;
    }

}


.page-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 100%;
    min-height: 100vh;
}

.page-content-wrapper {
    background-color: #ECF1F8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 0;
    min-width: 0;
    max-width: 100%;
    min-height: 1px;
}

.page-header {
    background-color: #fff;
    -webkit-box-shadow: 0 0 28px 0 rgba(86, 61, 124, .13);
    box-shadow: 0 0 28px 0 rgba(86, 61, 124, .13);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 2.5rem;
    position: relative;
    z-index: 1000;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    justify-content: space-between;
    @media (min-width: map-get($grid-breakpoints, "lg")) {
        padding: 0 2rem;
    }
    @media only screen and (max-width: map-get($grid-breakpoints, "lg")) {
        //padding: 0 1.5rem;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, .09);
    }
    @media only screen and (max-width: map-get($grid-breakpoints, "sm")) {
        [ngbdropdowntoggle]+.dropdown-menu {
            width: calc(100vw - 2rem)!important;
            right: 1rem!important;
            left: auto!important;
            top: $header-height !important;
            transform: scale(1)!important;
        }
    }
}

.page-content {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    @media (min-width: map-get($grid-breakpoints, "lg")) {
        padding: 1rem 2rem;
    }
    @media only screen and (max-width: map-get($grid-breakpoints, "lg")) {
        padding: .5rem;
        color: #222;
        font-size: 14px;
        min-height: calc(100vh - 6.9375rem);
    }
}

@media only screen and (max-width: map-get($grid-breakpoints, "lg")) {
    .mobile-nav-on .page-content-overlay {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, .09);
    }

    .page-content-overlay {
        background: 0 0;
        -webkit-transition: background .3s;
        transition: background .3s;
        position: fixed;
        z-index: 1001;
    }
}

.page-footer {
    height: 2.8125rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    color: #4d4d4d;
    font-size: .8125rem;
    padding: 0 2rem;
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
    @media only screen and (max-width: map-get($grid-breakpoints, "lg")) {
        border-top: 1px solid rgba(0, 0, 0, .09);
    }
}

.oslo-gray {
    .page-sidebar {
        background-color: #5b5b5b;
    }
    .page-content-wrapper {
        background-color: #faf8fb;
    }

    .bg-trans-gradient {
        background: linear-gradient(250deg, $profile-dropdown-header-bg--secondary, $profile-dropdown-header-bg--gray);
    }

    .header-btn:hover{
        border-color: $color-brand--oslo-gray !important;
        background: #a1a8ae !important;
    }

    .header-icon:not(:hover):not(.btn)>fa-icon:first-child {
        color: $color-brand--oslo-gray !important;
    }

    .nav-title {
        color: #868686 !important;
    }

    .bg-primary {
        background-color: $color-brand--oslo-gray !important;
        //color: #fff;
    }
}
