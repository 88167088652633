$item-row-colors: (
    positive: #e7f8eb,
    negative: #ffe2dd,
    neutral: #faffe5,
    success: #e4ffaf,
    warning: #fff5bb,
    disabled: #e4eaec
);
@each $color, $value in $item-row-colors {
    .item-row-#{$color}, .item-row-#{$color} > td {
        background: $value !important;
    }
}
