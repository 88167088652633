table .sorting {
    &,
    &_asc,
    &_desc {
        padding-right: 30px !important;
        position: relative;
        cursor: pointer;
    }
}

.sorting,
.sorting_asc,
.sorting_asc_disabled,
.sorting_desc,
.sorting_desc_disabled {
    &:before {
        right: 1rem !important;
        content: '↑';
    }
}

.sorting,
.sorting_asc,
.sorting_asc_disabled,
.sorting_desc,
.sorting_desc_disabled {
    &:after {
        right: .5rem !important;
        content: '↓';
    }
}

.sorting,
.sorting_asc,
.sorting_asc_disabled,
.sorting_desc,
.sorting_desc_disabled,
{
    &:after,
    &:before {
        position: absolute;
        bottom: .75rem;
        display: block;
        opacity: .3;
        font-size: 1.25rem;
        line-height: 1rem;
    }
}

.sorting_asc:before,
.sorting_desc:after {
    opacity: 1;
}
