/* width */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}


/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.grid-wrapper {
    &.collapsing {
        overflow-y: hidden;
    }

    table {
        tr.active {
            background-color: #E5E5E5;
            & > td:first-child {
                border-left: 5px solid #44A2F8;
            }
        }
    }
}

@import "variables";

.grid-scroll-wrapper {
    scrollbar-width: thin;
    position: relative;
    &--60vh {
        @media only screen and (min-width: map-get($grid-breakpoints, "sm")) {
            max-height: 60vh;
            overflow-y: auto;
        }
    }
    @media only screen and (max-width: map-get($grid-breakpoints, "sm")) {
        overflow-x: auto;
    }
    // TODO move to _table.scss ?
    .table {
        font-size: 13px;

        th, td {
            &:not(&.event-history-cell):not(&.actions) {
                padding: 9.5px;
            }
        }

        tbody tr {
            //height: 40px;
        }

        thead {
            box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%), 0 2px 0 #e9e9e9, inset 0 2px 0 #e9e9e9 !important;
        }

        td.actions {
            padding: 0;
            text-align: center;
            vertical-align: middle;

            .btn-group,
            .btn-group-vertical {
                &, & > .btn {
                    position: static;
                    z-index: auto;
                }
            }
        }
    }
}

