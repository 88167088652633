@import "src/styles/variables";

.modal-header:not(.default) {
    background-color: $blue-600;
    .modal-title {
        color: $gray-100;
    }
    .btn-close {
        @extend .btn-close-white;
    }
}

.modal-title {
    color: #656565;
    text-align: center;
    flex-grow: 1;
}

.modal-body {
    font-size: 0.85rem;
}

.btn-modal-wrapp:not(.modal-footer) {
    .btn-modal:not(:first-child) {
        @media only screen and (max-width: map-get($grid-breakpoints, "sm")) {
            margin-top: .5rem;
        }
        @media only screen and (min-width: map-get($grid-breakpoints, "sm")) {
            margin-left: .5rem;
        }
    }
}

.btn-modal {
    &-wrapp {
        @media only screen and (min-width: map-get($grid-breakpoints, "sm")) {
            text-align: right;
        }

        &.btn-group-sm {
            .btn-modal {
                min-width: 100px;
            }
        }
    }

    @media only screen and (max-width: map-get($grid-breakpoints, "sm")) {
        display: block;
        width: 100%;
    }
    min-width: 125px;
}

.create-return-popup {
    .modal-dialog {
        @media only screen and (min-width: map-get($grid-breakpoints, "sm")) {
            max-width: 1400px;
        }
    }
}

.modal,
.modal-backdrop {
    transition: width .3s;
}

.show-debug-panel {
    .modal,
    .modal-backdrop {
        width: calc(100% - $header-panel-width);
    }
}
