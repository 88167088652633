$language-bg: #b3d4fc;
$custom-variable-bg: #c8e6c9;
$comment-color: #a50;
$prolog-color: slategray;
$punctuation-color: #999;
$namespace-opacity: .7;
$attr-color: #00c; // #905;
$property-color: #4c8698; // #905;
$tag-color: #170; // #905;
$number-color: #164;
$selector-color: #a11; //#690;
$operator-color: #9a6e3a;
$atrule-color: #07a;
$function-color: #DD4A68;
$regex-color: #e90;

code[class*="language-"],
pre[class*="language-"] {
    color: black;
    background: none;
    text-shadow: 0 1px white;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    font-size: 1em;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;

    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}

pre[class*="language-"]::-moz-selection, pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection, code[class*="language-"] ::-moz-selection {
    text-shadow: none;
    background: $language-bg;
}

pre[class*="language-"]::selection, pre[class*="language-"] ::selection,
code[class*="language-"]::selection, code[class*="language-"] ::selection {
    text-shadow: none;
    background: $language-bg;
}

@media print {
    code[class*="language-"],
    pre[class*="language-"] {
        text-shadow: none;
    }
}

/* Code blocks */
pre[class*="language-"] {
    padding: 1em;
    margin: .5em 0;
    overflow: auto;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
    background: #f5f2f0;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
    padding: .1em;
    border-radius: .3em;
    white-space: normal;
}
.token {
    &.comment{
        color: $comment-color;
    }
    &.prolog,
    &.doctype,
    &.cdata {
        color: $comment-color;
    }
    &.punctuation {
        //color: $punctuation-color;
    }

    &.namespace {
        opacity: $namespace-opacity;
    }

    &.number {
        color: $number-color;
    }

    &.tag {
        color: $tag-color;
    }
    &.property,
    &.boolean,
    &.constant,
    &.symbol,
    &.deleted {
        color: $property-color;
    }

    &.attr-name {
        color: $attr-color;
    }
    &.string,
    &.selector,
    &.char,
    &.builtin,
    &.inserted {
        color: $selector-color;
    }

    &.atrule,
    &.attr-value,
    &.keyword {
        color: $atrule-color
    }

    &.function,
    &.class-name {
        color: $function-color
    }

    &.regex,
    &.important,
    &.variable {
        color: $regex-color;
    }

    &.important,
    &.bold {
        font-weight: bold;
    }
    &.italic {
        font-style: italic;
    }

    &.entity {
        cursor: help;
    }
    &.operator {}
    &.entity,
    &.url {
        color: $operator-color;
        /* This background color was intended by the author of this theme. */
        background: hsla(0, 0%, 100%, .5);
    }
}
//.language-css .token.string,
//.style .token.string {
//    color: $operator-color;
//    /* This background color was intended by the author of this theme. */
//    background: hsla(0, 0%, 100%, .5);
//}

.custom-variable {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    cursor: default;
    margin: -6px -2px -2px -2px;
    padding: 6px 2px 2px 2px;
    color: rgba(0, 0, 0, .87);
}

.bg-custom-variable {
    background-color: $custom-variable-bg;
}
