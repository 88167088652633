.nav-tabs {
    border-bottom: none;
    .nav-link {
        background-color: #edf1f2;
        border: 1px solid #dde6e9;
        color: #515253;
        //font-weight: bold;
        &:hover {
            background-color: #fff;
        }
    }
}
