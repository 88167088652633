[class*="loader-"] {
    display: inline-block;
    width: 1em;
    height: 1em;
    color: inherit;
    vertical-align: middle;
    pointer-events: none;
}

.loader-atom {
    transform: rotateZ(45deg);
    perspective: 1000px;
    border-radius: 50%;
    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: inherit;
        height: inherit;
        border-radius: 50%;
        animation: 1s spinA linear infinite;
    }
    &:before {
        transform: rotateX(70deg);
    }
    &:after {
        transform: rotateY(70deg);
        animation-delay: .4s;
    }
}

@keyframes rotate {
    0% {
        transform: translate(-50%, -50%) rotateZ(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotateZ(360deg);
    }
}

@keyframes rotateccw {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}

@keyframes spinA {
    0%,
    100% {
        box-shadow: .2em 0 0 0 currentcolor;
    }
    12% {
        box-shadow: .2em .2em 0 0 currentcolor;
    }
    25% {
        box-shadow: 0 .2em 0 0 currentcolor;
    }
    37% {
        box-shadow: -.2em .2em 0 0 currentcolor;
    }
    50% {
        box-shadow: -.2em 0 0 0 currentcolor;
    }
    62% {
        box-shadow: -.2em -.2em 0 0 currentcolor;
    }
    75% {
        box-shadow: 0px -.2em 0 0 currentcolor;
    }
    87% {
        box-shadow: .2em -.2em 0 0 currentcolor;
    }
}

.loader-pixel {
    position: relative;
    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        background-color: currentcolor;
        left: 50%;
        right: 0;
        top: 0;
        bottom: 50%;
        box-shadow: -.5em 0 0 currentcolor;
        animation: loader-pixel 1s linear infinite;
    }
    &:after {
        top: 50%;
        bottom: 0;
        animation-delay: .25s;
    }
}

@keyframes loader-pixel {
    0%,
    100% {
        box-shadow: -.5em 0 0 transparent;
        background-color: currentcolor;
    }
    50% {
        box-shadow: -.5em 0 0 currentcolor;
        background-color: transparent;
    }
}
.loader-spin-dash {
    border: .2em solid transparent;
    border-left-color: currentcolor;
    border-right-color: currentcolor;
    border-radius: 50%;
    animation: 1s loader-spin linear infinite;
}

@keyframes loader-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/////////////////////////////////////
.loader-circle {
    border: .2em solid currentcolor;
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: 1s loader-spin linear infinite;
    position: relative;
}

/////////////////////////////////////
.loader-satellite {
    border: 1px solid currentcolor;
    border-radius: 50%;
    animation: 1s loader-spin linear infinite;
    position: relative;
    &:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        top: -.2em;
        left: 50%;
        border: .2em solid currentcolor;
        border-radius: 50%;
    }
}

.loader_wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color-brand, .13);
    color: white;
    font-size: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    &--top {
        align-items: flex-start;
        padding-top: 20px;
    }
}
