//.p-link {
//    border-radius: 6px !important;
//}

.p-column-filter-menu-button {
    border-radius: 50% !important;
}

.p-element,
.p-component {
    //&.p-button,
    .p-button {
        border-radius: 6px;
    }
}

.p-picklist {
    font-size: 0.875rem;
    .p-picklist-filter-container {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }
    .p-picklist-filter-input {
        font-size: 0.875rem;
    }
    .p-picklist-list {
        padding-left: 0.5rem;
        margin-bottom: 0;
        .p-picklist-item {
            padding: 0.25rem 0.75rem;
        }
    }
    .p-inputtext {
        padding: 0.25rem 0.75rem;
    }
}
