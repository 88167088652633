.uploaded-image {
  display: inline-block;
  margin: 0 auto;
  position: relative;

  .btn {
    position: absolute;
    right: 0;
    border-width: 2px;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}
