.was-validated {
    .form-control.is-invalid {
        border-color: #dc3545;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");

        &:focus {
            border-color: #dc3545;
            box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
        }
    }

    ngb-datepicker .form-select {
        --bs-form-select-bg-icon: none !important;
        padding-right: .5rem !important;
    }
}
