.fs-lg {
    font-size: .9375rem !important;
}

.fs-sm {
    font-size: .8rem !important;
}

.fs-xs {
    font-size: .7rem !important;
}

.flex-1 {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.list-table {
    height: auto;
    display: table;
    margin: 0;
    padding: 0;

    & > li {
        display: table-cell;
        vertical-align: middle;
        position: relative;
        padding: 0;
    }
}

.clickable-item {
    border-bottom: 1px dashed $color-brand !important;
    cursor: pointer;
}

.no-clickable-item {
    border-bottom: none !important;
    cursor: text !important;
}

.cursor-pointer {
    cursor: pointer;
}

.text-truncate-lg {
    max-width: 200px;
}

.text-truncate-md {
    max-width: 160px;
}

.mxw-100 {
    max-width: 100px !important;
}

.mxw-200 {
    max-width: 200px !important;
}

.mnw-120 {
    min-width: 120px;
}

.mnw-175 {
    min-width: 175px;
}

.mnw-250 {
    min-width: 250px;
}

.mxw-400 {
    max-width: 400px !important;
}

.mxw-500 {
     max-width: 500px !important;
}

.img-mxw {
    max-width: 100%;
}

.mnh-0 {
    min-height: 0;
}

.mxh-150 {
    max-height: 150px;
}

.nl2br {
    white-space: pre-wrap;
}

.text-2rows {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
}

@media (max-width: 991.98px) {
    .d-md-down-none {
        display: none !important;
    }
}

.fade {
    transition: opacity 0.15s linear;
    @media (prefers-reduced-motion: reduce) {
        transition: none;
    }
}

.collapse:not(.show) {
    display: none !important;
}

.btn-sm-down-block {
    @include media-breakpoint-down(sm, $grid-breakpoints) {
        width: 100%;
        display: block;
    }
}

.comma-separated:not(:last-of-type):after {
    content: ", ";
}

$sizes: 55px, 60px, 75px, 80px, 85px, 100px, 110px, 120px, 125px, 135px, 150px, 170px, 250px;

@each $size in $sizes {
    .w-#{$size} {
        width: $size;
        max-width: $size;
        min-width: $size;
    }
}
