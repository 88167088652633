.dropdown-item {
    font-size: .8125rem;
    .dropdown-icon  {
        width: 1.25rem;
        display: inline-block;
        text-align: center;
    }
}
.dropdown-inner {
    max-height: 250px;
    overflow-y: auto;
}
.dropdown-menu.dropdown-lg {
    width: 17.5rem;
    height: auto;
    right: 1rem;
    left: auto;
}

.dropdown-non-toggle-arrow:after {
    content: none;
}
