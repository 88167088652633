/* You can add global styles to this file, and also import other style files */
@import "variables";
//@import 'bootstrap/scss/bootstrap';
@import 'bootstrap';
@import "bg-color";
@import "item-row-color";
@import "utils";
@import "page";
@import "card";
@import "accordion";
@import "pagination";
@import "sorting";
@import "dropdown";
@import "loaders";
@import "modals";
@import "nav";
@import "file-uploader";
@import "row-toggler";
@import "uploaded-image";
@import "badge";
//@import "panel-toolbar";

@import "table";
@import "ng-select";
@import "ngb-timepicker";
@import "select";
@import "grid&details-page";
@import "validated";
@import "angular-tree-component"; // @bugsplat/angular-tree-component/css/angular-tree-component.css

//@import '@circlon/angular-tree-component/css/angular-tree-component.css';
@import "@ng-select/ng-select/themes/default.theme.css";

/* Prismjs theme */
@import "prism"; //@import "prismjs/themes/prism.css";


//@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/themes/aura-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeng";

:root {
    --cropper-outline-color: rgba(0, 0, 0, 0.6);
}

btn,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus,
a,
a:active,
a:focus,
button,
button:active,
button:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem transparent;
    box-shadow: 0 0 0 0.2rem transparent;
}

body {
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-size: .8rem !important;
    letter-spacing: .1px;
}

.nav-menu-tooltip .tooltip-inner {
    background-color: darken(desaturate($primary, 0.21), 14.90);
}

.nav-menu-tooltip .arrow::before {
    border-right-color: darken(desaturate($primary, 0.21), 14.90);
}

.background {
    background: url(/assets/img/svg/pattern-1.svg) no-repeat center bottom fixed;
    background-size: cover;
}

app-inline-loader {
    display: inline-block;
}

//[role="button"]:not(.p-accordion-header-link) {
//    display: inline-block;
//}

.form-control:read-only {
    background-color: #f3f6fa;
    opacity: 1;
}
