.file-upload {
    display: flex;
    &-input {
        @extend .input-group;
        //@extend .mb-3;
        border-radius: 4px 0 0 4px;
        border: 1px solid #ced4da;
        background-color: rgba(239, 239, 239, 0.3);
        cursor: text;
        pointer-events: none;
        width: 100%;
    }

    &-btn {
        border-radius: 0 4px 4px 0;
        border: 1px solid #ced4da;
        border-left-width: 0;
        white-space: nowrap;

        &:hover {
            background-color: #ced4da;
        }
    }

    &-sm-input {
        @extend .file-upload-input;
        height: calc(1.5em + 0.5rem + 2px);
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem 0 0 0.2rem;

        ~ .file-upload-btn {
            height: calc(1.5em + 0.5rem + 2px);
            padding: 0.25rem 0.5rem;
            font-size: 0.875rem;
            line-height: 1.5;
            border-radius: 0 0.2rem 0.2rem 0;
        }
    }
}

